var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.addressQualification
    ? _c(
        "div",
        [
          _c(
            "h5",
            {
              staticClass:
                "md:text-2xl text-xl text-center font-bold primary-text leading-10",
            },
            [_vm._v(" Home address ")]
          ),
          _c("p", { staticClass: "text-center text-grey md:text-lg mt-2" }, [
            _vm._v(" Kindly provide us with your current address "),
          ]),
          _c("Input", {
            staticClass: "mt-8",
            attrs: {
              id: "address",
              type: "text",
              placeholder: "What's your house address?",
              width: "w-full",
            },
            on: {
              blur: function ($event) {
                return _vm.validateAddress()
              },
              focus: function ($event) {
                return _vm.handleError()
              },
            },
            model: {
              value: _vm.address.address,
              callback: function ($$v) {
                _vm.$set(_vm.address, "address", $$v)
              },
              expression: "address.address",
            },
          }),
          _c("transition", { attrs: { name: "fade-out" } }, [
            _vm.errorOnAddress
              ? _c("p", { staticClass: "text-brandRed text-xs" }, [
                  _vm._v(" Please select a valid address from the dropdown "),
                ])
              : _vm._e(),
          ]),
          _c("Input", {
            staticClass: "mt-8",
            attrs: {
              placeholder: "Add more details to this address (if any)",
              width: "w-full",
            },
            model: {
              value: _vm.address.additionalAddressDetails,
              callback: function ($$v) {
                _vm.$set(_vm.address, "additionalAddressDetails", $$v)
              },
              expression: "address.additionalAddressDetails",
            },
          }),
          _c(
            "div",
            { staticClass: "grid md:grid-cols-2 mt-8 gap-4" },
            [
              _c("Select", {
                staticClass: "w-full",
                attrs: {
                  placeholder: "State",
                  options: _vm.states,
                  reduce: function (category) {
                    return category.name
                  },
                  label: "name",
                },
                model: {
                  value: _vm.address.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "state", $$v)
                  },
                  expression: "address.state",
                },
              }),
              _c("Select", {
                staticClass: "w-full",
                attrs: {
                  placeholder: "LGA",
                  options: _vm.lga,
                  reduce: function (category) {
                    return category.name
                  },
                  label: "name",
                },
                model: {
                  value: _vm.address.lga,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "lga", $$v)
                  },
                  expression: "address.lga",
                },
              }),
            ],
            1
          ),
          _c("Button", {
            staticClass: "mt-8",
            attrs: {
              width: "w-full",
              text: "Continue",
              type: "submit",
              disabled: _vm.disableButton,
              loading: _vm.loading,
            },
            on: { click: _vm.submitAddress },
          }),
          _c(
            "Modal",
            { attrs: { display: _vm.showDisableCreditModal } },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-xl md:text-lg mt--6 font-semibold text-brandPurple",
                },
                [_vm._v(" We can only process full payment at the moment ")]
              ),
              _c("p", { staticClass: "mt-4 text-grey text-sm" }, [
                _vm._v(
                  "You cannot access credit at the moment because we are revising and improving our installment payment plans."
                ),
              ]),
              _c("Button", {
                staticClass: "mt-12",
                attrs: { text: "Go to dashboard", width: "w-full" },
                on: { click: _vm.handleDashboard },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }