<template>
  <div v-if="!addressQualification">
    <h5
      class="md:text-2xl text-xl text-center font-bold primary-text leading-10"
    >
      Home address
    </h5>
    <p class="text-center text-grey md:text-lg mt-2">
      Kindly provide us with your current address
    </p>
    <Input
      class="mt-8"
      id="address"
      type="text"
      placeholder="What's your house address?"
      width="w-full"
      v-model="address.address"
      @blur="validateAddress()"
      @focus="handleError()"
    />
    <transition name="fade-out">
      <p v-if="errorOnAddress" class="text-brandRed text-xs">
        Please select a valid address from the dropdown
      </p>
    </transition>
    <Input
      placeholder="Add more details to this address (if any)"
      class="mt-8"
      v-model="address.additionalAddressDetails"
      width="w-full"
    />
    <div class="grid md:grid-cols-2 mt-8 gap-4">
      <Select
        class="w-full"
        placeholder="State"
        v-model="address.state"
        :options="states"
        :reduce="(category) => category.name"
        label="name"
      />
      <Select
        class="w-full"
        placeholder="LGA"
        v-model="address.lga"
        :options="lga"
        :reduce="(category) => category.name"
        label="name"
      />
    </div>
    <Button
      @click="submitAddress"
      class="mt-8"
      width="w-full"
      text="Continue"
      type="submit"
      :disabled="disableButton"
      :loading="loading"
    />
    <!-- <p
      class="
        text-font-semibold text-brandPurple text-center
        cursor-pointer
        mt-8
      "
      @click="skipAddress"
    >
      Skip, I will do it later
    </p> -->
    <Modal :display="showDisableCreditModal">
      <span class="text-xl md:text-lg mt--6 font-semibold text-brandPurple">
        We can only process full payment at the moment
      </span>
      <p class="mt-4 text-grey text-sm">You cannot access credit at the moment because we are revising and improving our installment payment plans.</p>
      <Button  class="mt-12" text="Go to dashboard" width="w-full" @click="handleDashboard"/>
    </Modal>
  </div>

</template>
<script>
import { mapActions, mapState } from "vuex";
import { Input, Select, Upload } from "@/UI/Input";
import { Button } from "@/UI/Button";
import Location from "@/database/location.json";
import { SUBMIT_ADDRESS } from "@/utils/api/userInfo.js";
import {Modal} from '@/UI/Modals'
export default {
  components: {
    Input,
    Select,
    Upload,
    Button,
    Modal
  },
  data: () => ({
    address: {
      address: "",
      state: "",
      lga: "",
      additionalAddressDetails: "",
    },
    location: Location,
    lga: [],
    loading: false,
    valid: {
      address: false,
    },
    isAddressValid: false,
    errorOnAddress: false,
    showDisableCreditModal: false
  }),
  computed: {
    // ...mapState({
    //   addressCreditQualification: (state) =>
    //     state?.userInfo?.creditQualification?.preConditionResult
    //       ?.CONTACT_INFORMATION_CHECK?.eligibilityStatus,
    // }),
    ...mapState({
      addressQualification: (state) =>
        state?.dashboard?.dashboardData?.customerDto?.addressSorted,
      enableCredit:(state) => state?.auth?.enableCredit
    }),
    states() {
      return this.location.map((location) => location.state);
    },
    rules() {
      return {
        address: this.isAddressValid,
        state: this.address.state.length > 0,
        lga: this.address.lga.length > 0,
        // bill: this.address.utilityBillImage.length !== 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  watch: {
    "address.state"(value) {
      this.location.forEach((state) => {
        if (state.state.name === value) {
          this.lga = state.state.locals;
        }
      });
    },
  },
  mounted() {
    if (!this.addressQualification) {
      this.googleAutocomplete();
    } else {
      this.$emit("success");
    }
  },
  methods: {
    ...mapActions("dashboard", [
      "handleKycStep",
      "showKycStep",
      "handleCreditQualificationStep",
      "showCreditQualificationStep",
      'handleShowReminder'
    ]),
    ...mapActions("notification", ["showAlert"]),
    skipAddress() {
      this.showKycStep(false);
      this.handleShowReminder(true)
      // this.handleCreditQualificationStep(CREDIT_QUALIFICATION_STEPS.work);
      // this.showCreditQualificationStep(true);
    },
    handleDashboard(){
      this.handleShowReminder(false);
      this.showKycStep(false);

    },
    submitAddress() {
      this.loading = true;
      SUBMIT_ADDRESS(this.address)
        .then(() => {
          this.loading = false;
          if(this.enableCredit){
            this.$emit("success");
          }else{
            this.$store.commit('dashboard/SET_DASHBOARD_COMPLETION')
            this.showDisableCreditModal  = true
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // google maps integration starts here
    validateAddress() {
      if (this.isAddressValid) {
        this.errorOnAddress = false;
        document.getElementById("address").style.borderColor = "#000";
      } else {
        this.errorOnAddress = true;
        document.getElementById("address").style.borderColor = "#C70039";
      }
    },
    handleError() {
      if (this.isAddressValid) {
        this.address.address = "";
        this.address.state = "";
        this.address.lga = "";
      }
      document.getElementById("address").style.borderColor = "#000";
      this.errorOnAddress = false;
      this.isAddressValid = false;
    },
    googleAutocomplete() {
      // document.getElementById("address").placeholder = "";

      let options = {
        // types: ["geocode"],
        componentRestrictions: { country: "ng" },
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address"),
        options
      );
      this.autocomplete.addListener("place_changed", () => {
        this.isAddressValid = true;
        this.validateAddress();
        let place = this.autocomplete.getPlace();
        // console.log(place);
        let ac = place.address_components;
        const street = {
          no: "",
          name: "",
          neighborhood: "",
        };

        ac.forEach((address) => {
          if (address.types[0] === "street_number") {
            street.no = address.long_name;
          }
          if (address.types[0] === "route") {
            street.name = address.long_name;
          }
          if (address.types[0] === "neighborhood") {
            street.neighborhood = address.long_name;
          }
          if (address.types[0] === "administrative_area_level_1") {
            if (address.long_name === "Federal Capital Territory") {
              address.long_name = "FCT";
            }
            this.address.state = address.long_name.split(" State")[0];
          }
          if (address.types[0] === "administrative_area_level_2") {
            if (address.short_name === "AMAC") {
              address.long_name = "Municipal Area Council";
            }
            this.address.lga = address.long_name;
          }
        });
        // console.log(this.address.lga);

        // this.address.address =
        //   street.no + " " + street.name + ", " + street.neighborhood;
        this.address.address = place.name;
        if (this.address.state === "" || this.address.lga === "") {
          this.isAddressValid = false;
          this.showAlert({
            display: true,
            description:
              "Please select a valid street address from the dropdown",
            info: "Eg: 19 Babatunde Kuboye Street, Lagos",
            type: "error",
          });
        }
      });
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await this.$axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBjOiTISUUC_q6n1uoft7eXMOZyAd44EKQ"
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          this.address.address = data.results[0].formatted_address;
          this.isAddressValid = true;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
